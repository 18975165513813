/* Import Tailwind's base, components, and utilities */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Global styles */

/* Light mode styles (default) */
body {
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for theme changes */
}

/* Dark mode styles */
body.dark-mode {
  background-color: #1a202c;
  color: #e2e8f0;
}

/* Custom styles for other elements */
.header {
  background-color: #1a202c; /* Light mode header background */
}

.header.dark-mode {
  background-color: #2d3748; /* Dark mode header background */
}

.button {
  background-color: #007bff; /* Light mode button background */
  color: #ffffff; /* Light mode button text color */
}

.button.dark-mode {
  background-color: #2b6cb0; /* Dark mode button background */
  color: #edf2f7; /* Dark mode button text color */
}

/* Add more custom styles for dark mode as needed */
